import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Flex, Box, Image, Text } from 'rebass';
import { GatsbyImage } from 'gatsby-plugin-image';
import { InlineWidget } from 'react-calendly';

import ReactCountryFlag from 'react-country-flag';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGlobeAmericas } from '@fortawesome/free-solid-svg-icons';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';

import StyledIconLink from '../../components/StyledIconLink';
import { countries } from '../../helpers/countries';

const StyledAvatar = styled.div`
  border: 0.5px solid #d0d0d0;
  border-radius: 100%;
  background-color: white;
  overflow: hidden;
  width: 155px;
  height: 155px;

  > div {
    border: 10px solid white;
    border-radius: 100%;
    overflow: hidden;
    width: 100%;
    height: 100%;
  }
`;

const StyledFlag = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0.5px solid #d0d0d0;
  border-radius: 100%;
  // color: ${({ theme }) => theme.colors.nextonBlue};
  color: #afafaf;
  background-color: white;
  font-size: 18px;
  width: 42px;

  > img {
    width: 20px !important;
    height: 20px !important;
  }
`;

const StyledPill = styled.div`
  color: ${({ theme }) => theme.colors.nextonBlue};
  background: #d4f0f6;
  font-size: 14px;
  font-weight: 500;
  border-radius: 10px;
  padding: 4px 18px;
  margin: 0px 6px 6px 0px;
  height: 25px;
`;

const ScreenerBox = ({ screener, avatar }) => {
  const [shortJob, setShortJob] = useState(screener.job_title);
  const [secondaryStack, setSecondaryStack] = useState(null);
  const [country, setCountry] = useState(null);

  // Remove Technical Interviewer from Job Title
  const getShortJob = job => {
    if (job.includes(' and Technical Interviewer')) {
      setShortJob(job.replace(' and Technical Interviewer', ''));
    }
  };

  // Split Secondary Stack into an array
  const splitSecondaryStack = stack => {
    if (stack === ('null' || 'Ninguno')) {
      setSecondaryStack(null);
      return;
    }
    const stackArray = stack.split(';');
    const filteredArray = stackArray.filter(each => each !== screener.primary_stack);
    setSecondaryStack(filteredArray);
  };

  // Get country code to show country flag
  const getCountryFlag = countryString => {
    const countryArray = countries.find(singleCountry => singleCountry.name === countryString);
    setCountry(countryArray);
  };

  useEffect(() => {
    getShortJob(screener.job_title);
    splitSecondaryStack(screener.secondary_stack);
    getCountryFlag(screener.country);
  }, []);

  return (
    <Box
      mt={['150px', '100px']}
      mb={['50px', '70px']}
      mx={['10px', '20px']}
      style={{
        position: 'relative',
      }}>
      <Box
        key={screener.calendly}
        bg='white'
        // width={['100%', '380px']}
        minWidth={['100%', '380px']}
        maxWidth={['100%', '420px']}
        mx='auto'
        height='810px'
        style={{
          border: '0.5px solid #D0D0D0',
          borderRadius: '13px',
          boxShadow: '0px 20px 25px -14px rgba(161, 161, 161, 0.46)',
          overflow: 'hidden',
        }}>
        <Flex
          flexDirection='column'
          alignItems='center'
          mx='20px'
          mt='72px'
          style={{ borderBottom: '0.5px solid #D0D0D0' }}>
          <Box style={{ position: 'absolute', top: '-82px' }}>
            <StyledAvatar style={{ position: 'relative' }}>
              <div>
                {screener.url_picture === 'null' ? (
                  <GatsbyImage image={avatar} alt={`${screener.first_name} ${screener.last_name}`} />
                ) : (
                  <Image src={screener.url_picture} alt={`${screener.first_name} ${screener.last_name}`} width='100%' />
                )}
              </div>
            </StyledAvatar>

            <Flex
              justifyContent='space-between'
              flexDirection='row-reverse'
              mt='-50px'
              style={{ position: 'relative', zIndex: '1' }}>
              <StyledIconLink href={screener.linkedin_profile} target='_blank' rel='noopener' aria-label='linkedin'>
                <FontAwesomeIcon icon={faLinkedin} />
              </StyledIconLink>

              <StyledFlag>
                {country ? (
                  <ReactCountryFlag countryCode={country.code} aria-label={country.name} svg />
                ) : (
                  <FontAwesomeIcon icon={faGlobeAmericas} />
                )}
              </StyledFlag>
            </Flex>
          </Box>

          <Text
            fontSize='26px'
            color='nextonBlue'
            mt='6px'
            mb='4px'
            width={['300px', '360px']}
            textAlign='center'
            style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
            {screener.first_name} {screener.last_name}
          </Text>
          <Text fontSize={['14px', '16px']} fontWeight='300'>
            {shortJob}
          </Text>
          <Flex
            mt='20px'
            mb='14px'
            flexWrap='wrap'
            justifyContent='center'
            height='62px'
            maxWidth='370px'
            style={{ overflow: 'hidden' }}>
            {screener.primary_stack !== ('null' || 'Ninguno') && <StyledPill>{screener.primary_stack}</StyledPill>}
            {secondaryStack &&
              secondaryStack.map(stack => (
                <StyledPill style={{ backgroundColor: '#EAF8FA', fontWeight: '400' }} key={stack}>
                  {stack}
                </StyledPill>
              ))}
          </Flex>
        </Flex>
        <InlineWidget url={screener.calendly} pageSettings={{ hideEventTypeDetails: true }} />
      </Box>
    </Box>
  );
};

export default ScreenerBox;
