import styled from 'styled-components';

const StyledIconLink = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 42px;
  height: 42px;
  font-size: 18px;
  text-decoration: none;
  color: white;
  background-color: ${props => props.theme.colors.secondary};
  border-radius: 50%;
  cursor: pointer;
  transition: 0.4s;

  &:hover {
    filter: brightness(115%);
  }
`;

export default StyledIconLink;
