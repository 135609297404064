import React from 'react';
import { Box } from 'rebass';
import Layout from '../components/layout/Layout';
import MetaData from '../components/MetaData';
import SchedulingHero from '../sections/scheduling/SchedulingHero';

const SchedulingPage = () => {
  return (
    <>
      <MetaData
        title='Schedule your Interview'
        description='Schedule your Interview with one of our technical interviewers.'
      />
      <Layout selected='scheduling' layoutTheme='white'>
        <Box className='full-width hero' bg='nextonLightBlue'>
          <SchedulingHero />
        </Box>
      </Layout>
    </>
  );
};

export default SchedulingPage;
