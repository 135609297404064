import React from 'react';
import styled from 'styled-components';
import { Box } from 'rebass';

const LightYellowBubble = styled(Box)`
  background-color: #ffd55945;
  position: absolute;
  top: 250px;
  left: 0%;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  z-index: -1;
`;

const LightOrangeBubble = styled(Box)`
  background-color: #ffefe4;
  position: absolute;
  bottom: 145px;
  right: 0%;
  width: 320px;
  height: 320px;
  border-radius: 50%;
  z-index: -1;
`;

const SchedulingBubbles = () => (
  <Box>
    <LightYellowBubble />
    <LightOrangeBubble />
  </Box>
);

export default SchedulingBubbles;
