import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Flex, Box, Text } from 'rebass';
import { useStaticQuery, graphql } from 'gatsby';
import Slider from 'react-slick';
import ScreenerBox from './ScreenerBox';
import SchedulingBubbles from '../../components/scheduling/SchedulingBubbles';
import Spinner from '../../components/spinner/Spinner';

const NextArrow = styled.div`
  width: unset;
  height: unset;
  right: -50px;

  :before {
    content: '〉';
    font-family: unset;
    font-size: 60px;
    color: #00a5c8;
  }
`;

const PrevArrow = styled.div`
  width: unset;
  height: unset;
  left: -50px;

  :before {
    content: '〈';
    font-family: unset;
    font-size: 60px;
    color: #00a5c8;
  }
`;

const settings = {
  autoplay: false,
  speed: 1500,
  dots: true,
  dotsClass: 'slick-dots slick-thumb-mobile',
  customPaging: () => <span>dot</span>,
  arrows: true,
  nextArrow: <NextArrow />,
  prevArrow: <PrevArrow />,
  slidesToShow: 3,
  slidesToScroll: 3,
  infinite: false,
  responsive: [
    {
      breakpoint: 1315,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        dots: true,
      },
    },
    {
      breakpoint: 895,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: true,
      },
    },
    {
      breakpoint: 640,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: true,
        arrows: false,
        dotsClass: 'slick-dots slick-thumb-mobile slick-thumb-mobile-top',
      },
    },
  ],
};

const SchedulingHero = () => {
  const [data, setData] = useState(false);
  let searchParams;

  const getScreeners = async () => {
    // example schaduling url: http://localhost:8000/scheduling/?r=fs&t=py;rs;he&c=&n=Isaias&w=17214455
    try {
      const response = await fetch(`${process.env.GATSBY_IR_ENDPOINT}/match_new?${searchParams.toString()}`);
      const resultData = await response.json();
      setData(resultData);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    searchParams = new URLSearchParams(window.location.search);
    if (searchParams.has('name') || searchParams.has('n')) getScreeners();
  }, []);

  const { allFile } = useStaticQuery(graphql`
    {
      allFile(filter: { relativeDirectory: { eq: "images/scheduling/avatars" } }) {
        nodes {
          childImageSharp {
            gatsbyImageData(quality: 100, placeholder: NONE, layout: FULL_WIDTH)
          }
          name
        }
      }
    }
  `);
  const avatars = [];
  allFile.nodes.map(img => avatars.push({ name: img.name, ...img.childImageSharp.gatsbyImageData }));

  return (
    <>
      <Box
        className='body-layout'
        minHeight='calc(100vh - 200px)'
        mt='120px'
        mb='120px'
        style={{ zIndex: '1', position: 'relative' }}>
        <Flex
          flexDirection='column'
          alignItems='center'
          textAlign='center'
          mb='20px'
          style={{ zIndex: '1', position: 'relative' }}>
          {!data.candidate_name ? (
            <>
              <Text fontSize={['50px', '64px']} color='nextonBlue' mb='5px' as='h1'>
                Welcome!
              </Text>
              <Text fontSize={['24px', '30px']} lineHeight='1.3'>
                Please wait while we load your interviewers
              </Text>
              <Spinner />
            </>
          ) : (
            <>
              <Text fontSize={['50px', '64px']} color='nextonBlue' mb='5px'>
                Welcome {data.candidate_name}!
              </Text>
              <Text fontSize={['24px', '30px']} lineHeight='1.3'>
                {data.screeners_info?.length
                  ? 'Schedule your Interview with one of our technical interviewers:'
                  : 'There are no available interviewers right now! Please reach out to your recruiter, who will gladly assist you with more options.'}
              </Text>
            </>
          )}
        </Flex>
        <Box textAlign='center' width='100%' py='10px' mb='-120px' style={{ zIndex: '1', position: 'relative' }}>
          {!data.screeners_info ? (
            <Spinner />
          ) : (
            <>
              <Slider {...settings}>
                {data.screeners_info.map(screener => (
                  <ScreenerBox
                    key={screener.calendly}
                    screener={screener}
                    avatar={avatars[Math.floor(Math.random() * 6)]}
                  />
                ))}
              </Slider>
              <SchedulingBubbles />
            </>
          )}
        </Box>
      </Box>
    </>
  );
};

export default SchedulingHero;
